<!--
/**
 *@author hhy
 *@date 2023年2月9日
 财务管理 对账管理 平台收支账单
*/
-->
<template>
  <scroll-layout>
    <template #header>
      <div v-if="pageTopButtons.length > 0" class="button-wrap">
        <ykc-search
          ref="YkcSearch"
          :data="searchData"
          :searchForm="searchParam"
          confirm-btn-label="查询"
          clear-btn-label="恢复默认"
          @click="handleSearchConfirm"
          @clean="handleSearchClean"></ykc-search>
      </div>
      <section class="el-row--flex card-info-area">
        <div class="card-info purse-card-info">
          <div class="common-icon">
            <div class="big-icon income-icon"></div>
          </div>
          <div class="common-text">
            <div class="purse-text">
              <!--              收入-->
              <help-icon :is-ask-show="'抽成费用 = 收入'" label="收入" />
            </div>
            <div class="purse-balance">
              <span>{{ totalData.revenueTotal ?? '—' }}</span>
              <span class="purse-unit">元</span>
            </div>
          </div>
        </div>
        <div class="white-stone"></div>
        <div class="card-info purse-card-info">
          <div class="common-icon">
            <div class="big-icon"></div>
          </div>
          <div class="common-text">
            <div class="purse-text">
              <help-icon :is-ask-show="'平台活动+平台卡券+会员优惠 = 支出'" label="支出" />
            </div>
            <div class="purse-balance">
              <span>{{ totalData.expensesTotal ?? '—' }}</span>
              <span class="purse-unit">元</span>
            </div>
          </div>
        </div>
      </section>
    </template>
    <ykc-table
      ref="YkcTable"
      :data="tableData"
      :columns="tableColumns"
      title="收支账单"
      operateFixedType="right"
      :operateButtons="tableOperateButtons.filter(item => item.enabled())">
      <template #headerButtons>
        <ykc-button
          v-for="(button, index) in pageButtons.filter(item => item.enabled())"
          :key="button.id || index"
          v-bind="button"
          @click="handlePageButtonClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </template>
      <ykc-pagination
        slot="pagination"
        ref="YkcPagination"
        direct="flex-end"
        :total="pageInfo.total"
        :page-size.sync="pageInfo.pageSize"
        :current-page.sync="pageInfo.pageIndex"
        @size-change="handlePaginationSizeChange"
        @current-change="handlePaginationCurrentChange"></ykc-pagination>
    </ykc-table>
  </scroll-layout>
</template>

<script>
  import { reconciliationApi } from '@/service/apis';
  import { code, getLastTime, getLastYear, offlineExport } from '@/utils';
  import HelpIcon from '@/views/financialManagement/components/HelpIcon.vue';

  export default {
    name: 'PlatformReceiptsPaymentsStatementsList',
    components: {
      // CustomList,
      HelpIcon,
    },
    data() {
      return {
        id: '',
        isEdit: false,
        showDrawer: false,
        pageTopButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        pageButtons: [
          {
            id: '1',
            enabled: () => code('finance:reconci:platform:export'),
            text: '导出',
            type: 'plain',
            handleClick: () => {
              offlineExport({
                downloadType: 'platform_order_bill',
                jsonNode: {
                  ...this.searchParam,
                },
              });
            },
          },
        ],
        tableData: [],
        totalData: {},
        tableColumns: [
          { label: '账单时间', prop: 'summaryDate', minWidth: '100px' },
          { label: '平台活动抵扣', prop: 'totalDiscountAmt', minWidth: '100px' },
          { label: '红包抵扣', prop: 'redPackDiscountAmount', minWidth: '100px' },
          { label: '平台卡券抵扣', prop: 'totalCardDiscountAmt', minWidth: '100px' },
          { label: '会员优惠', prop: 'memberDiscountAmt', minWidth: '100px' },
          { label: '抽成费用', prop: 'totalCommissionAmt', minWidth: '100px' },
        ],
        tableOperateButtons: [
          {
            id: '2',
            text: '明细',
            enabled: () => code('finance:reconci:platform:detail'),
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/financialManagement/platformReceiptsPaymentsStatement/platformReceiptsPaymentsStatementsDetail',
                query: { billTime: row.summaryDate, billDateType: this.searchParam.billDateType },
              });
            },
          },
        ],
        pageInfo: {
          pageIndex: 1,
          pageSize: 10,
        },
        searchParam: {
          billingTime: [],
          billEndTime: '',
          billStartTime: '',
          billDateType: 1,
        },
      };
    },
    computed: {
      searchData() {
        return [
          {
            isDateType: true,
            label: '账单时间',
            key: 'billingTime',
            comName: 'YkcDatePicker',
            change: this.handleChange,
          },
        ];
      },
    },
    created() {
      const startDate = getLastTime('yyyy-MM-dd', 7);
      const endDate = getLastTime('yyyy-MM-dd', 1);

      this.searchParam.billingTime = [startDate, endDate];
      this.searchParam.billStartTime = startDate;
      this.searchParam.billEndTime = endDate;

      this.searchTableList();
    },
    methods: {
      /**
       * 切换日月
       */
      handleChange(obj) {
        console.log('obj===', obj);
        const { select, event } = obj;

        // 下拉选择操作默认日期
        if (event === 'select' && select === 'monthrange') {
          // 切换到月
          const monthStr = getLastYear(6, 1);
          const startDateStr = monthStr.startTime;
          const endDateStr = monthStr.endTime;

          this.searchParam.billingTime = [startDateStr, endDateStr];
          this.searchParam.billStartTime = startDateStr;
          this.searchParam.billEndTime = endDateStr;
          this.searchParam.billDateType = 2;
        } else if (event === 'select' && select === 'daterange') {
          // 切换到日
          const startDateStr = getLastTime('yyyy-MM-dd', 7);
          const endDateStr = getLastTime('yyyy-MM-dd', 1);

          this.searchParam.billingTime = [startDateStr, endDateStr];
          this.searchParam.billStartTime = startDateStr;
          this.searchParam.billEndTime = endDateStr;
          this.searchParam.billDateType = 1;
        }
      },
      /**
       * 根据条件查询数据
       */
      searchTableList() {
        Promise.all([
          reconciliationApi.queryPlatformBillForPage({
            ...this.pageInfo,
            ...this.searchParam,
          }),
          reconciliationApi.queryPlatformBillTotalByTime(this.searchParam),
        ]).then(([res1, res2]) => {
          this.tableData = res1.records;
          this.pageInfo.total = res1.total || 0;
          this.totalData = res2;
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(searchData) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParam, searchData);

        // 时间选择
        if (
          searchData.billingTime &&
          Array.isArray(searchData.billingTime) &&
          searchData.billingTime.length > 0
        ) {
          [this.searchParam.billStartTime, this.searchParam.billEndTime] = searchData.billingTime;
        }

        console.log(' this.searchParam++++', this.searchParam);

        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(searchData) {
        Object.assign(this.searchParam, searchData);

        // 切换成日，默认状态
        const startDate = getLastTime('yyyy-MM-dd', 7);
        const endDate = getLastTime('yyyy-MM-dd', 1);

        this.searchParam.billingTime = [startDate, endDate];
        this.searchParam.billStartTime = startDate;
        this.searchParam.billEndTime = endDate;
        this.searchParam.billDateType = '1';
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
    },
  };
</script>

<style scoped lang="scss">
  .card-info-area {
    box-sizing: border-box;
    .card-info {
      display: flex;
      box-sizing: border-box;
      flex-wrap: nowrap;
      flex-grow: 1;
      width: 892px;
      height: 125px;
      //line-height: 125px;
      background: #ffffff;
      box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
      align-content: center;
      align-items: center;
    }
    .common-icon {
      display: inline-flex;
      box-sizing: border-box;
      width: 70px;
      height: 70px;
      margin-left: 30px;
      background: rgba(77, 140, 253, 0.1);
      border-radius: 14px;
      line-height: 70px;
      align-content: center;
      text-align: center;
      align-items: center;
      justify-content: center;
      .big-icon {
        width: 32px;
        height: 32px;
        display: inline-block;
        background-image: url('~@/assets/images/financial/customDriver/purse@2x.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
      .big-icon.income-icon {
        background-image: url('~@/assets/images/financial/reconciliation/income@2x.png');
      }
    }
    .common-text {
      display: inline-flex;
      flex-direction: column;
      margin-left: 30px;
      .account-name {
        //width: 108px;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 25px;
      }
      .purse-text {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 20px;
        //display: inline-flex;
        //align-items: center;
      }
      .purse-balance {
        //width: 261px;
        height: 40px;
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 40px;
        .purse-unit {
          width: 19px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 20px;
        }
      }
    }
    .common-label-key {
      display: inline-block;
      width: 60px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9b9b9b;
      line-height: 17px;
    }
    .common-label-val {
      display: inline-block;
      //width: 24px;
      //height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
      margin-left: 8px;
    }
    .white-stone {
      width: 16px;
      height: 125px;
    }
  }
</style>
